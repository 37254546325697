import { Dialog } from '@material-ui/core';
import React, { useState } from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { Button, useRecordContext } from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { LocalizationProvider } from '@mui/lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { IRequestProps } from './ActionButtons.types';

interface IProps {
    onClose: () => void;
    request: (value: IRequestProps) => Promise<void>;
    onConfirm: (data: any) => Promise<void>;
    content?: string;
}

export const ConfirmDialog: React.FC<IProps> = ({
    onConfirm,
    onClose,
    request,
    content,
}) => {
    const [comment, setComment] = useState('');
    const record = useRecordContext();

    const handleSubmit = async () => {
        if (!comment) {
            return;
        }

        request({
            data: {
                id: record.id,
                params: {
                    comment,
                },
            },
            fetch: onConfirm,
            onFinish: onClose,
        });
    };

    return (
        <Dialog open>
            <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
                <Box
                    sx={{
                        padding: '1rem 2rem',
                    }}
                >
                    {content && <Typography>{content}</Typography>}
                    <Stack width={'100%'} sx={{ mb: 2, flexShrink: 1 }}>
                        <TextField
                            multiline
                            label="Введите комментарий"
                            onChange={(e) => {
                                setComment(e.target.value);
                            }}
                            required
                        />
                    </Stack>

                    <Stack direction="row" sx={{ gap: '1rem' }}>
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            size="large"
                            startIcon={<CheckIcon />}
                            label="Сохранить"
                        />
                        <Button
                            onClick={onClose}
                            variant="contained"
                            size="large"
                            sx={{
                                backgroundColor: 'salmon',
                            }}
                            startIcon={<CancelIcon />}
                            label="Отмена"
                        />
                    </Stack>
                </Box>
            </LocalizationProvider>
        </Dialog>
    );
};
