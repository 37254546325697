import React, { useState } from 'react';
import { useRecordContext, useNotify, Button } from 'react-admin';
import { Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ExtendDialog } from './ExtendDIalog';
import PassService from 'src/services/pass_service';
import { useNavigate } from 'react-router-dom';
import { PassStatusPending, PassStatusCancelled } from 'src/util';
import { IRequestProps } from './ActionButtons.types';
import { ConfirmDialog } from './ConfirmDialog';

export const ActionButtons: React.FC = () => {
    const record = useRecordContext();
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [isRefuseDialogOpen, setIsRefuseDialogOpen] = useState(false);
    const [isExtendDialogOpen, setIsExtendDialogOpen] = useState(false);
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

    const notify = useNotify();
    const navigate = useNavigate();

    const request = async ({
        data,
        fetch,
        onFinish,
    }: IRequestProps): Promise<any> => {
        try {
            await fetch({
                ...data,
            });
            success();
        } catch (error: any) {
            notify(error.message, { type: 'error' });
        } finally {
            onFinish?.();
        }
    };

    const success = () => {
        notify('Успешно', { type: 'success' });
        setTimeout(() => {
            navigate(0);
        }, 2000);
    };

    const ConfirmButton = () => (
        <>
            <Button
                onClick={() => setIsConfirmDialogOpen(true)}
                variant="contained"
                size="large"
                sx={{
                    backgroundColor: 'seagreen',
                }}
                startIcon={<CheckIcon />}
                label="Согласовать"
            />
            {isConfirmDialogOpen && (
                <ConfirmDialog
                    onClose={() => setIsConfirmDialogOpen(false)}
                    content="Вы уверены что хотите согласовать эту визитку?"
                    onConfirm={PassService.passConfirm}
                    request={request}
                />
            )}
        </>
    );

    const RefuseButton = () => (
        <>
            <Button
                onClick={() => setIsRefuseDialogOpen(true)}
                variant="contained"
                size="large"
                sx={{
                    backgroundColor: 'tomato',
                }}
                startIcon={<CancelIcon />}
                label="Отказать"
            />
            {isRefuseDialogOpen && (
                <ConfirmDialog
                    onClose={() => setIsRefuseDialogOpen(false)}
                    content="Вы уверены что хотите отклонить эту визитку?"
                    onConfirm={PassService.passRefuse}
                    request={request}
                />
            )}
        </>
    );

    const ExtendButton = () => (
        <>
            <Button
                onClick={() => setIsExtendDialogOpen(true)}
                variant="contained"
                size="large"
                startIcon={<AccessTimeIcon />}
                label="Продлить"
            />
            {isExtendDialogOpen && (
                <ExtendDialog
                    onClose={() => setIsExtendDialogOpen(false)}
                    request={request}
                />
            )}
        </>
    );

    const CancelButton = () => (
        <>
            <Button
                onClick={() => setIsCancelDialogOpen(true)}
                variant="contained"
                size="large"
                label="Отменить"
                sx={{
                    backgroundColor: 'tomato',
                }}
                startIcon={<CancelIcon />}
            />
            {isCancelDialogOpen && (
                <ConfirmDialog
                    onClose={() => setIsCancelDialogOpen(false)}
                    request={request}
                    onConfirm={PassService.passCancel}
                />
            )}
        </>
    );

    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={{ gap: '1rem', width: '100%' }}
        >
            {record.to_be_agreed && record.status === PassStatusPending && (
                <ConfirmButton />
            )}
            {record.to_be_agreed && record.status === PassStatusPending && (
                <RefuseButton />
            )}
            {record.status === PassStatusPending && <ExtendButton />}
            {record.status !== PassStatusCancelled && <CancelButton />}
        </Stack>
    );
};
