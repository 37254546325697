import { User } from './User';
import { Location } from './Location';
import { MakeUnnecessary } from './MakeUnnecessary';
import { differenceInDays, format, isValid, parse } from 'date-fns';
import { ru } from 'date-fns/locale';
import { pluralize } from './util';
import { IPartner, IPartnerType } from './Partner';
import { SxProps } from '@mui/material';

export interface JobTitle {
    id: number;
    name: string;
}

export interface PassType {
    color: string;
    id: number;
    name: string;
}

export function mapOpenCloseToLabel(openClose: boolean) {
    return openClose ? 'Допущен' : 'Не допущен';
}

export function mapOpenCloseToBgColor(openClose: boolean) {
    return openClose ? '#E8F5E9' : '#FFEBEE';
}

export function mapOpenCloseToFgColor(openClose: boolean) {
    return openClose ? '#4CAF50' : '#F44336';
}

export const statusChipOptions = (status: string) => {
    const options: SxProps = {
        fontWeight: 500,
    };

    switch (status) {
        case 'pending':
            options.color = 'gray';
            options.backgroundColor = '';
            break;
        case 'done':
            options.color = '#4CAF50';
            options.backgroundColor = '#E8F5E9';
            break;
        case 'cancelled':
            options.color = '#F44336';
            options.backgroundColor = '#FFEBEE';
            break;
    }

    return options;
};

export function calculateValidDays(date?: string): {
    date: string;
    days: string;
} {
    const empty = {
        date: '',
        days: '',
    };

    if (!date) {
        return empty;
    }

    const validDate = parse(date, 'd.M.yyyy HH:mm', new Date());

    if (!isValid(validDate)) {
        return empty;
    }

    const daysDiff = differenceInDays(validDate, new Date());
    const dateStr = format(validDate, 'dd MMM yyyy', { locale: ru });

    return {
        date: dateStr,
        days: `(${daysDiff > 0 ? 'ещё' : 'просрочено на'} ${pluralize(
            daysDiff < 0 ? -daysDiff : daysDiff,
            ['день', 'дня', 'дней']
        )})`,
    };
}

export interface Pass {
    comment: string;
    created_at: string;
    creator_user: User;
    half_zone: boolean;
    id: number;
    job_title: JobTitle;
    partner: IPartner;
    partner_type: IPartner;
    location: Location;
    magnet_card: boolean;
    open_close: boolean;
    pass_type: PassType;
    in_absentia: boolean; //  - заочно
    to_be_agreed: boolean; //  - на согласовании
    was_agreed: boolean; //  - был согласован
    online_store: boolean; //  - интернет магазин
    was_delayed: boolean; //  - признак отсрочки
    valid_until: string;
    valid_until_text: string;
    status?: string;
    internal_pass_type_id: number;
}

export interface PassesResponse {
    items: MakeUnnecessary<Pass>[];
    total_count: number;
}

export enum OptionTooltip {
    inAbsentia = 'Пропуск арендатора в случае его отсутствия',
    toBeAgreed = 'Пропуск пользователям менее 18 лет',
    onlineStore = 'Торговля ведется через интернет магазин',
}

export interface PassForm {
    partner: IPartner;
    location: Location;
    jobTitle: JobTitle;
    comment: string;
    openClose: boolean;
    halfZone: boolean;
    magnetCard: boolean;
    inAbsentia: boolean;
    toBeAgreed: boolean;
    onlineStore: boolean;
    partnerType: IPartnerType;
    passType: string;
    passTypePeriod: string;
}

export interface CreatePassDto {
    pass_type_id: string;
    partner_id: number;
    partner_name?: string;
    location_id: number;
    job_title_id: number;
    comment: string;
    half_zone: boolean;
    magnet_card: boolean;
    open_close: boolean;
    in_absentia: boolean;
    to_be_agreed: boolean;
    online_store: boolean;
    partner_type_id: number;
    pass_type_period: string;
}

export enum PassFilter {
    Delayed = 'delayed',
    Pending = 'pending',
    Done = 'done',
    Cancelled = 'cancelled',
}

export type TPassTypeEntity = {
    id: number;
    name: string;
    color: string;
};
