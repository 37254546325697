import { Box, SxProps, Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDoubleClick } from '../../../../../../../hooks/useDoubleClick';
import { TGetZonesEntity } from 'src/services';
import { useZones } from '../../../hooks/useZones';
import { ZoneItemCollapse } from './ZoneItemCollapse';

interface IProps {
    readonly item: TGetZonesEntity;
    readonly parentData?: TGetZonesEntity[];
}

export const ZoneItem: React.FC<IProps> = memo(({ item, parentData }) => {
    const [expanded, setExpanded] = useState(false);
    const [selected, setSelected] = useState(false);
    const { selectedZones, setSelectedZones } = useZones();
    const isChildAssignment = item.users.some((i) => i.child_assignment);

    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        const zones = [...selectedZones].filter((i) => i.id !== item.id);

        if (e.ctrlKey) {
            setSelectedZones(selected ? [...zones] : [...zones, item]);
        } else if (e.shiftKey) {
            const intersection = selectedZones.filter((value) =>
                parentData.some((i) => i.id === value.id)
            );
            let isIntersection = false;
            setSelectedZones(
                [...parentData].reduce((acc, curr, _, arr) => {
                    if (item.id === curr.id) arr.splice(1);
                    if (intersection?.length) {
                        if (
                            intersection.some((i) => i.id === curr.id) &&
                            !isIntersection
                        ) {
                            isIntersection = true;
                        }
                        if (isIntersection) {
                            acc.push(curr);
                        }
                    } else {
                        acc.push(curr);
                    }
                    return acc;
                }, [])
            );
        } else {
            setSelectedZones(selected ? [] : [item]);
        }
    };

    const onDoubleClick = () => setExpanded((prev) => !prev);

    const myDoubleClickCallback = useDoubleClick(onClick, onDoubleClick, 200);

    useEffect(() => {
        setSelected(selectedZones.some((i) => i.id === item.id));
    }, [selectedZones]);

    const sx: Record<string, SxProps> = {
        container: {
            display: 'flex',
            gap: '.25rem',
            flexWrap: 'nowrap',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            backgroundColor: selected ? '#E7F3FE' : 'none',
            borderRadius: '.5rem',
            userSelect: 'none',
            fontSize: '17px',
        },
        expandIcon: {
            width: '1.25rem',
            transition: 'all .1s ease-out;',
            transform: `rotate(${expanded ? '90' : '0'}deg)`,
            cursor: 'pointer',
            color: isChildAssignment ? '#97999B' : 'red',
        },
        expandDesc: {
            overflowWrap: 'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#97999B',
            paddingLeft: '.5rem',
        },
        text: {
            fontWeight: 'bold',
            color: !isChildAssignment && 'red',
        },
    };

    return (
        <Box>
            <Box sx={sx.container} onClick={myDoubleClickCallback}>
                {item.has_childs && (
                    <Box
                        onClick={(e) => {
                            e.stopPropagation();
                            setExpanded((prev) => !prev);
                        }}
                    >
                        <ChevronRightIcon sx={sx.expandIcon} />
                    </Box>
                )}
                <Typography component="div">
                    <Box sx={sx.text}>{item.name}</Box>
                </Typography>

                {item.users?.length ? (
                    <Box sx={sx.expandDesc}>
                        {`${item.users.length} (${item.users
                            .map((u) => u.name)
                            .join(', ')})`}
                    </Box>
                ) : (
                    <></>
                )}
            </Box>
            {item.has_childs && (
                <ZoneItemCollapse expanded={expanded} item={item} />
            )}
        </Box>
    );
});
