import { CircularProgress, Paper, SxProps, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDebounce } from 'src/hooks/useDebounce';

import {
    TAsyncSelectOptions,
    TAsyncSelectProps,
} from './FormAsyncSelect.types';

export const FormAsyncSelect: React.FC<TAsyncSelectProps> = ({
    name,
    placeholder,
    defaultValue,
    fetch,
    ...rest
}) => {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState<TAsyncSelectOptions[]>([]);

    const debouncedValue = useDebounce(search?.toLocaleLowerCase(), 500);

    const {
        control,
        formState: { errors },
    } = useFormContext() || {};

    const handleFetch = async () => {
        try {
            setLoading(true);
            setOptions(await fetch(debouncedValue));
        } finally {
            setLoading(false);
        }
    };

    const CustomPaper = (props) => {
        return (
            <Paper
                elevation={8}
                sx={{
                    marginBottom: '10px',
                }}
                {...props}
            />
        );
    };

    useEffect(() => {
        if (debouncedValue) {
            handleFetch();
        }
    }, [debouncedValue]);

    useEffect(() => {
        handleFetch();
    }, []);

    const sx: Record<string, SxProps> = useMemo(
        () => ({
            autocomplete: {
                '.MuiInputBase-input': {
                    height: '30px',
                },
                '.MuiFormLabel': {
                    '&-root': {
                        padding: '0 4px',
                        top: '-4px',
                        color: errors[name] ? '#E00505' : 'inherit',

                        '&.Mui-focused': {
                            color: errors[name] ? '#E00505' : 'inherit',
                            top: '-12px',
                            bgcolor: 'white',
                        },
                    },
                    '&-filled': {
                        top: '-12px !important',
                        bgcolor: 'white',
                    },
                },
                '.MuiInputBase-root': {
                    bgcolor: 'transparent',
                    border: errors[name]
                        ? '1px solid #E00505'
                        : '1px solid #97999B',
                    borderRadius: '4px',
                    padding: '4px 8px 2px 8px',
                    display: 'flex',
                    alignItems: 'start',

                    '&:before': {
                        borderBottom: 'none',
                    },
                    '&:after': {
                        borderBottom: 'none',
                    },
                    '&:hover': {
                        bgcolor: 'transparent',
                        '&:before': {
                            borderBottom: 'none',
                        },
                        '&:after': {
                            borderBottom: 'none',
                        },
                    },
                    '.MuiCircularProgress-root': {
                        marginTop: '6px',
                    },
                },
            },
        }),
        [errors[name]]
    );

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange } }) => (
                <Autocomplete
                    forcePopupIcon={false}
                    noOptionsText={'Не найдено'}
                    loadingText={'Идет поиск'}
                    loading={loading}
                    disablePortal
                    options={options}
                    sx={sx.autocomplete}
                    onChange={(_, option) => {
                        onChange(option?.value || undefined);
                    }}
                    getOptionLabel={(option) => option.label}
                    PaperComponent={CustomPaper}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={placeholder}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            onChange={(e) => {
                                setSearch(e.target.value || '');
                            }}
                        />
                    )}
                />
            )}
        />
    );
};
