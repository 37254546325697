import { Box, SxProps, Typography } from '@mui/material'
import PersonOffIcon from '@mui/icons-material/PersonOff';

export const Empty: React.FC = () => {

  const sx: Record<string, SxProps> = {
        container: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            padding: '1rem 2rem',
        },
        icon: { fontSize: '40px', color: '#960303' },
        text: { fontSize: '15px', fontWeight: 500 },
    };

    return (
        <Box sx={sx.container}>
            <PersonOffIcon sx={sx.icon} />
            <Typography sx={sx.text}>
                Сотрудники не найдены
            </Typography>
        </Box>
    );
}