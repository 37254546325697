import { Box, Button, InputAdornment, Stack, TextField } from '@mui/material';
import { Add, QrCode, Search } from '@mui/icons-material';
import { FilterLabel } from './FilterLabel';
import { CardList } from './CardList';
import { CreateCardDialog } from './CreateCardDialog';
import { useDialog } from '../../../hooks/useDialog';
import { CreateCard } from './CreateCard';
import { PassFilter } from '../../../models/Passes';
import { useState } from 'react';
import { CardStatusSelect } from './CardStatusSelect';

export const Cards = () => {
    const [isOpen, onOpen, onClose] = useDialog();
    const [creatingIsOpen, creatingOnOpen, creatingOnClose] = useDialog();
    const [showStatusSelect, setShowStatusSelect] = useState(false);
    const [selectedType, setSelectedType] = useState<string>();

    const handlePassTypeSelect = (passType: string) => {
        setSelectedType(passType);
        setShowStatusSelect(false);
        creatingOnOpen();
    };

    return (
        <Stack>
            <Stack direction="row" spacing={1} p={1}>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="ФИО или пропуск"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                ></TextField>

                <Button
                    size="small"
                    variant="contained"
                    sx={{ minWidth: 'auto' }}
                >
                    <QrCode />
                </Button>
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                px={1}
                pb={2}
                sx={{ overflowX: 'auto' }}
            >
                <FilterLabel status={PassFilter.Delayed} label="Отсрочка" />
                <FilterLabel status={PassFilter.Pending} label="Создан" />
                <FilterLabel status={PassFilter.Done} label="Оформлен" />
                <FilterLabel status={PassFilter.Cancelled} label="Отменён" />
            </Stack>
            <Box
                sx={{
                    overflowY: 'auto',
                    position: 'absolute',
                    top: '6rem',
                    bottom: '6rem',
                    left: 0,
                    right: 0,
                    paddingBottom: '4rem',
                }}
            >
                <CardList />
            </Box>
            <Button
                onClick={() => setShowStatusSelect(true)}
                variant="contained"
                size="large"
                sx={{
                    position: 'absolute',
                    left: '1rem',
                    right: '1rem',
                    bottom: '7rem',
                }}
            >
                <Stack direction="row" spacing={1}>
                    Выписать визитку
                    <Add />
                </Stack>
            </Button>
            <CreateCardDialog
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                openCreating={creatingOnOpen}
            />
            <CreateCard
                open={creatingIsOpen}
                onClose={creatingOnClose}
                passType={selectedType}
            />
            <CardStatusSelect
                open={showStatusSelect}
                onToggle={setShowStatusSelect}
                onSelect={handlePassTypeSelect}
            />
        </Stack>
    );
};
