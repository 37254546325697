export const StatusNonPaid = 'non_paid';
export const StatusPool = 'pool';
export const StatusCollection = 'collection';
export const StatusRepaid = 'repaid';
export const StatusCancelled = 'cancelled';

export const Statuses = [
  {
    'id': StatusNonPaid,
    'name': 'Не погашен',
  },
  {
    'id': StatusPool,
    'name': 'Пул',
  },
  {
    'id': StatusCollection,
    'name': 'recalculation',
  },
  {
    'id': StatusRepaid,
    'name': 'Погашен',
  },
  {
    'id': StatusCancelled,
    'name': 'Отменен',
  }];

export const PassStatusPending = 'pending';
export const PassStatusDone = 'done';
export const PassStatusCancelled = 'cancelled';
export const PassStatusDelayed = 'delayed';

export const PassStatuses = [
  {id: PassStatusPending, name: 'Не оформлена'},
  {id: PassStatusDone, name: 'Оформлен'},
  {id: PassStatusCancelled, name: 'Отменен'},
  {id: PassStatusDelayed, name: 'Острочка'},
];

export const PassFlags = [
  {id: 'in_absentia', name: 'Заочно'},
  {id: 'online_store', name: 'Интернет-магазин'},
  {id: 'half_zone', name: 'Зона 1/2'},
  {id: 'magnet_card', name: 'Магнитная карта'},
];

export function FormatStatus(id) {
  const status = Statuses.find(el => el.id === id);
  return status ? status.name : '';
}

export function FormatFineType(fineType) {
  return `${fineType.code}`;
  // if (fineType.code > 0) {
  //   return `${fineType.name} (код ${fineType.code})`
  // }
  // return fineType.name
}

export function FormatFineReason(fineReason) {
  return fineReason.name;
}

export function FormatError(error) {
  if (error.response) {
    if (error.response.status === 403) {
      return 'Недостаточно прав';
    } else if (error.response.status === 500) {
      return 'Ошибка сервера';
    } else if (error.response.data && error.response.data.message) {
      return error.response.data.message;
    } else {
      return 'Неизвестная ошибка';
    }
  } else if (error.request) {
    return 'Нет соединения с сервером';
  } else {
    return 'Неизвестная ошибка';
  }
}

export function FormatRole(role) {
  if (role === 'author') {
    return 'Автор';
  } else if (role === 'administrator') {
    return 'Администратор';
  } else if (role === 'cashier') {
    return 'Кассир';
  }
  return '';
}

export function FormatFineCollectionStatus(status) {
  if (status === 'pending') {
    return 'в процессе';
  } else if (status === 'complete') {
    return 'завершена';
  } else if (status === 'cancelled') {
    return 'отменена';
  }
  return '';
}

export function FormatJobTitle(pass) {
  let s = '';
  if (pass.partner_type) {
    s += pass.partner_type.name;
  }
  if (pass.job_title) {
    s += ' - ' + pass.job_title.name;
  }
  return s;
}

export function DownloadFile(blob, filename) {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

export function PassBgColor(pass) {
  if (pass.was_agreed) {
    return '#FFF8DC';
  } else if (pass.to_be_agreed) {
    return '#FFA500';
  } else if (pass.status === 'done') {
    return '#98FB98';
  } else if (pass.status === 'cancelled') {
    return '#FA8072';
  } else if (pass.status === 'delayed') {
    return '#00BFFF';
  }
  return '#FFF';
}

export function PassTypeName(passTypeID) {
  switch (passTypeID) {
    case 2:
      return "Пропуск";
    case 1:
      return "Магнитная карта";
    default:
      return "Пропуск";
  }
}

export function InternalPassTypeName(passTypeID) {
  if (passTypeID === undefined || passTypeID === null) {
    return "Торговая точка";
  }
  passTypeID = String(passTypeID);
  switch (passTypeID) {
    case "1":
      return "Торговая точка";
    case "2":
      return "Торговая точка (1 день)";
    case "3":
      return "Склад";
    case "4":
      return "Услуга";
    case "5":
      return "Ночная витрина";
    case "6":
      return "Магнитная карта";
    default:
      return "Торговая точка";
  }
}

export function InternalPassTypeBgColor(passTypeID) {
  if (passTypeID === undefined || passTypeID === null) {
    return "#32cd32";
  }
  passTypeID = String(passTypeID);
  switch (passTypeID) {
    case "1":
      return "#32cd32";
    case "2":
      return "#d30000";
    case "3":
      return "#ff8c00";
    case "4":
      return "#00bfff";
    case "5":
      return "#9370db";
    case "6":
      return "#808080";
    default:
      return "#32cd32";
  }
}