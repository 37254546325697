import { baseApi } from './index';
import { TGetListItemsResponse } from 'src/models/Lists';
import { TGetListItemHistoryResponse, TUpdateListStatusRequest } from 'src/services/lists';

export const ListsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getListsItems: builder.query<
            TGetListItemsResponse,
            {
                status?: number;
            }
        >({
            query: (params) => ({
                url: 'list_items',
                params,
            }),
            providesTags: ['Lists'],
        }),

        getListItemHistory: builder.query<TGetListItemHistoryResponse, number>({
            query: (id) => ({
                url: `history/list_items/${id}`,
            }),
        }),

        updateListStatus: builder.mutation<void, TUpdateListStatusRequest>({
            query: ({id, body}) => ({
                url: `list_items/${id}/status`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Lists'],
        }),
    }),
});

export const {
    useGetListsItemsQuery,
    useGetListItemHistoryQuery,
    useUpdateListStatusMutation,
} = ListsApi;
