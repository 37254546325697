import { Box, SxProps, Typography } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';

export const Empty: React.FC = () => {
    const sx: Record<string, SxProps> = {
        container: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            padding: '1rem 2rem',
        },
        icon: { fontSize: '40px', color: '#E5E6E6' },
        text: { fontSize: '15px', clor: '#7D8083' },
    };

    return (
        <Box sx={sx.container}>
            <ListAltIcon sx={sx.icon} />
            <Typography sx={sx.text}>
                Нажмите на список, чтобы посмотреть детальную информацию
            </Typography>
        </Box>
    );
};
