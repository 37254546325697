import { Box, SwipeableDrawer, SxProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { useGetPassTypesQuery } from 'src/api/passes';
import { TPassTypeEntity } from 'src/models/Passes';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SubtitlesIcon from '@mui/icons-material/Subtitles';

interface IProps {
    readonly onToggle: React.Dispatch<React.SetStateAction<boolean>>;
    readonly open: boolean;
    readonly onSelect: React.Dispatch<React.SetStateAction<string>>;
}

export const CardStatusSelect: React.FC<IProps> = ({
    onSelect,
    open,
    onToggle,
}) => {
    const { data } = useGetPassTypesQuery(null);

    const sxList: SxProps = {
        padding: '0.5rem',
        display: 'flex',
        gap: '0.5rem',
        flexDirection: 'column',
    };

    const sxItem = (item: TPassTypeEntity): SxProps => ({
        borderRadius: '1rem',
        padding: '0.75rem 1rem',
        color: !item.color || item.color === '#ffffff' ? 'black' : 'white',
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center',
        cursor: 'pointer',
    });

    const renderIcon = (item: TPassTypeEntity): ReactNode => {
        switch (item.id) {
            case 1:
                return <CreditCardIcon />;
            case 2:
                return <SubtitlesIcon />;
            case 3:
                return <SubtitlesIcon />;
            case 4:
                return <SubtitlesIcon />;
            case 5:
                return <SubtitlesIcon />;
            case 6:
                return <SubtitlesIcon />;
            default:
                return <></>;
        }
    };

    if (!data?.length) {
        return <></>;
    }

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={() => onToggle(false)}
            onOpen={() => onToggle(true)}
        >
            <Box sx={sxList}>
                {data.map((item) => (
                    <Box
                        style={{ backgroundColor: item.color }}
                        sx={sxItem(item)}
                        onClick={() => onSelect(String(item.id))}
                        key={item.id}
                    >
                        {renderIcon(item)}
                        {item.name}
                    </Box>
                ))}
            </Box>
        </SwipeableDrawer>
    );
};
