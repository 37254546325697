import { Box, SxProps, Typography } from '@mui/material';

export const Empty: React.FC = () => {
    const sx: Record<string, SxProps> = {
        container: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            padding: '1rem 2rem',
            height: '100%',
            justifyContent: 'center'
        },
        text: { fontSize: '15px', clor: '#7D8083' },
    };

    return (
        <Box sx={sx.container}>
            <Typography sx={sx.text}>
                Ничего не найдено<br/>Снимите фильтры или создайте обращения
            </Typography>
        </Box>
    );
};
