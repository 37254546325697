import { Box, SxProps, Typography } from '@mui/material';
import { ZoneItem } from './components/ZoneItem';
import { useEffect } from 'react';
import { useZones } from '../../hooks/useZones';

export const Zones: React.FC = () => {
    const { getZones, zones } = useZones();

    const sx: Record<string, SxProps> = {
        container: {
            paddingTop: '1rem',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '1rem',
        },
        title: { color: '#085391', display: 'flex', marginBottom: 0, fontSize: '33px' },
    };

    useEffect(() => {
        getZones();
    }, [getZones]);

    return (
        <Box sx={sx.container}>
            <Box sx={sx.header}>
                <Typography gutterBottom sx={sx.title}>
                    Зоны
                </Typography>
            </Box>
            {zones.map((item) => <ZoneItem key={`zone-${item.id}`} item={item} />)}
        </Box>
    );
};
