import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';

const MyCustomComponent = () => {
    return (
        <Link to="/ui/admin/fines" target={"_blank"}>
            <Button sx={{color: 'white'}}>Админка для штрафов</Button>
        </Link>
    );
};

export default MyCustomComponent;
