import { Box, SxProps, Typography } from '@mui/material';
import PersonOffIcon from '@mui/icons-material/PersonOff';

interface IProps {
    readonly selectedZone: boolean;
}

export const Empty: React.FC<IProps> = ({ selectedZone }) => {
    const sx: Record<string, SxProps> = {
        container: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            padding: '1rem 2rem',
        },
        icon: { fontSize: '40px', color: '#E5E6E6' },
        text: { fontSize: '15px', clor: '#7D8083' },
    };

    return (
        <Box sx={sx.container}>
            <PersonOffIcon sx={sx.icon} />
            <Typography sx={sx.text}>
                {selectedZone
                    ? 'Нет сотрудников в выбранной зоне. Нажмите «Назначить», чтобы назначить сотрудника на зону'
                    : 'Нажмите на зону, чтобы посмотреть назначенных сотрудников'}
            </Typography>
        </Box>
    );
};
