import { Box, SxProps, Typography } from '@mui/material';
import { useZones } from '../../hooks/useZones';
import { Empty } from './components/Empty';
import { AssignedList } from './components/AssignedList';

export const Selection: React.FC = () => {
    const { assignedUsers, selectedZones } = useZones();

    const sx: Record<string, SxProps> = {
        container: {
            paddingTop: '1rem',
        },
        title: { fontSize: '17px', fontWeight: 500 },
        subtitle: { color: '#8A8C8F' },
    };

    return (
        <Box sx={sx.container}>
            <Typography sx={sx.title}>Сотрудники в выделенной зоне</Typography>
            <Typography variant="caption" sx={sx.subtitle}>
                {selectedZones?.[0]?.combined_name || 'Зона не выделена'}
            </Typography>
            {assignedUsers?.length ? (
                <AssignedList />
            ) : (
                <Empty selectedZone={Boolean(selectedZones?.length)} />
            )}
        </Box>
    );
};
