import { Collapse, Skeleton, SxProps } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { TGetZonesEntity, ZonesService } from 'src/services';
import { useNotify } from 'react-admin';
import { ZoneItem } from './ZoneItem';

interface IProps {
    readonly item: TGetZonesEntity;
    readonly expanded: boolean;
}

export const ZoneItemCollapse: React.FC<IProps> = memo(({ item, expanded }) => {
    const [data, setData] = useState<TGetZonesEntity[]>();
    const notify = useNotify();

    useEffect(() => {
        if (expanded) {
            setData(undefined);

            (async () => {
                try {
                    const response = await ZonesService.getZones({
                        parentId: item.id || '0',
                    });
                    setData(response);
                } catch (error) {
                    notify(error.message, { type: 'error' });
                }
            })();
        }
    }, [expanded]);

    const sx: Record<string, SxProps> = {
        collapse: {
            paddingLeft: '2rem',
            borderTop: '1px solid #E7F3FE !important'
        },
    };

    return (
        <Collapse in={expanded} sx={sx.collapse}>
            {data ? (
                data?.length ? (
                    data.map((zone) => <ZoneItem key={`sub-${zone.id}`} item={zone} parentData={data} />)
                ) : (
                    'Нет данных'
                )
            ) : (
                <Skeleton />
            )}
        </Collapse>
    );
})