import { Box, Chip, Paper, Skeleton } from '@mui/material';
import React from 'react';
import { useLists } from './hooks/useLists';
import { Empty, ListItem } from './components';
import { useGetListsItemsQuery } from 'src/api/lists';
import { LIST_STATUSES } from 'src/utils';

export const ListsPage: React.FC = () => {
    const { selectedStatus, setSelectedStatus } = useLists();

    const { data: listItems, isFetching } = useGetListsItemsQuery(
        { status: selectedStatus },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    return (
        <Paper
            sx={{
                height: 'calc(100% - 6rem)',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    flexWrap: 'wrap',
                    padding: '0.5rem',
                }}
            >
                {LIST_STATUSES.map((i) => (
                    <Chip
                        label={i.name}
                        variant={
                            i.id === selectedStatus ? undefined : 'outlined'
                        }
                        onClick={() =>
                            setSelectedStatus(
                                selectedStatus === i.id ? undefined : i.id
                            )
                        }
                    />
                ))}
            </Box>
            {isFetching ? (
                <Box>
                    {new Array(10).fill(0).map(() => (
                        <Skeleton variant="text" sx={{ minWidth: '2rem' }} />
                    ))}
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                        flexGrow: '1',
                        overflowY: 'scroll',
                        padding: '0 0.5rem',
                    }}
                >
                    {listItems?.length ? (
                        listItems?.map((item) => (
                            <ListItem key={item.id} item={item} />
                        ))
                    ) : (
                        <Empty />
                    )}
                </Box>
            )}
        </Paper>
    );
};
