import { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import {
    ListsService,
    TListItemEntity,
    TListItemHistoryEntity,
    TUpdateListStatusRequest,
} from 'src/services/lists';

export const useLists = () => {
    const [listItem, setListItem] = useState<TListItemEntity>();
    const [listItemHistory, setListItemHistory] =
        useState<TListItemHistoryEntity[]>();
    const [selectedRowId, setSelectedRowId] = useState<string>();
    const notify = useNotify();

    const fetchListItem = useCallback(() => {
        ListsService.getListItem(selectedRowId).then((res) => {
            setListItem(res);
        });
    }, [selectedRowId]);

    const fetchListItemHistory = useCallback(() => {
        ListsService.getListItemHistory(selectedRowId).then((res) => {
            setListItemHistory(res);
        });
    }, [selectedRowId]);

    const onUpdateStatus = async ({
        id,
        values,
        callback,
    }: {
        id: number;
        values: TUpdateListStatusRequest['body'];
        callback: () => void;
    }) => {
        try {
            await ListsService.updateStatus({
                id,
                body: values,
            });
            notify('Успешно', { type: 'success' });
            callback();
        } catch (error) {
            notify(error.response?.data?.message, { type: 'error' });
        }
    };

    useEffect(() => {
        if (selectedRowId) {
            fetchListItem();
            fetchListItemHistory();
        }
    }, [selectedRowId, fetchListItem, fetchListItemHistory]);

    return {
        setSelectedRowId,
        listItem,
        listItemHistory,
        selectedRowId,
        onUpdateStatus,
    };
};
