import { Box, Button, ButtonGroup, SxProps, Typography } from '@mui/material';
import { useState } from 'react';
import { TGetZonesEntity, TGetZonesUsersEntity } from 'src/services';
import { ZonesResetModal } from '../../../../common/ZonesResetModal';
import { ZonesRemoveModal } from '../../../../common/ZonesRemoveModal';

interface IProps {
    readonly item: TGetZonesUsersEntity;
    readonly zone: TGetZonesEntity;
}

export const ZoneItem: React.FC<IProps> = ({ item, zone }) => {
    const [showResetModal, setShowResetModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);

    const sx: Record<string, SxProps> = {
        item: {
            borderBottom: '1px solid #CFE8FC',
            padding: '.5rem .75rem',
            display: 'flex',
            gap: '.5rem',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            '&:last-child': {
                borderBottom: 'none !important',
            },
        },
        title: {
            fontSize: '15px',
            fontWeight: 'bold',
        },
        info: {
            fontSize: '12px',
            color: '#8A8C8F',
        },
        buttonsContainer: {
            '&>button': {
                textTransform: 'none',
            },
        },
        button: {
            fontSize: '11px',
            padding: '0 0.5rem',
        },
    };

    return (
        <Box sx={sx.item}>
            <Box>
                <Typography sx={sx.title}>{zone.name}</Typography>
                {/* <Typography sx={sx.info}>{item.combined_name}</Typography> */}
            </Box>
            <ButtonGroup orientation="vertical" sx={sx.buttonsContainer}>
                <Button
                    key="one"
                    size="small"
                    sx={sx.button}
                    onClick={() => setShowResetModal(true)}
                >
                    Переназначить
                </Button>
                <Button
                    key="two"
                    size="small"
                    sx={sx.button}
                    onClick={() => setShowRemoveModal(true)}
                >
                    Снять с зоны
                </Button>
            </ButtonGroup>
            {showResetModal && (
                <ZonesResetModal
                    item={item}
                    onClose={() => setShowResetModal(false)}
                />
            )}
            {showRemoveModal && (
                <ZonesRemoveModal
                    item={item}
                    onClose={() => setShowRemoveModal(false)}
                />
            )}
        </Box>
    );
};
