import { Box, SxProps } from '@mui/material';
import { CardItem } from './CardItem';
import { useZones } from '../../../hooks/useZones';

export const AssignedList: React.FC = () => {
    const { assignedUsers } = useZones();

    const sx: Record<string, SxProps> = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: '.75rem',
        },
    };

    return (
        <Box sx={sx.container}>
            {assignedUsers.map((user) => (
                <CardItem item={user} key={`selection.${user.id}`} />
            ))}
        </Box>
    );
};
