import instance from '../api';
import {
    TGetListItemHistoryResponse,
    TGetListItemResponse,
    TUpdateListStatusRequest,
} from './type';

export const ListsService = {
    getListItem: async (id: string): Promise<TGetListItemResponse> =>
        (await instance.get(`/admin/list_items/${id}`)).data,

    getListItemHistory: async (
        id: string
    ): Promise<TGetListItemHistoryResponse> =>
        (await instance.get(`/admin/history/list_items/${id}`)).data,

    updateStatus: async ({
        body,
        id,
    }: TUpdateListStatusRequest): Promise<TGetListItemResponse> =>
        (await instance.put(`/admin/list_items/${id}`, body)).data,
};
