import { Box, Collapse, Typography } from '@mui/material';
import React, { useState } from 'react';
import { TListItemEntity } from 'src/services/lists';
import moment from 'moment-timezone';
import { declension } from 'src/utils/helpers';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useGetListItemHistoryQuery } from 'src/api/lists';
import { CircleOutlined } from '@mui/icons-material';
import { ListHistoryItem } from '../ListHistoryItem';
import { UpdateStatusModal } from '../UpdateStatusModal';
import { ItemStatus } from '../ItemStatus';

interface IProps {
    readonly item: TListItemEntity;
}

export const ListItem: React.FC<IProps> = ({ item }) => {
    const [expanded, setExpanded] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const { data: history, isFetching } = useGetListItemHistoryQuery(item.id, {
        skip: !expanded,
    });

    const diffDays =
        moment(item.end_at.split('.').reverse().join('-')).diff(
            moment(),
            'days'
        ) + 1;

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.25rem',
                    border: '1px solid #E5E6E6',
                    borderRadius: '0.25rem',
                    // paddingBottom: '0.25rem',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <Box
                        sx={{
                            padding: '0.25rem 0.5rem 0 0.5rem',
                            fontSize: '15px',
                            lineHeight: '120%',
                            fontWeight: '600',
                        }}
                    >
                        {item.location.name}
                    </Box>
                    <ItemStatus
                        status={item.status.name}
                        onClick={() => setShowModal(true)}
                    />
                </Box>
                <Typography
                    sx={{
                        fontSize: '18px',
                        lineHeight: '120%',
                        fontWeight: '500',
                        padding: '0 0.5rem',
                    }}
                >
                    {item.partner.full_name}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 0.5rem',
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: '13px',
                                lineHeight: '120%',
                                color: '#646668',
                            }}
                        >
                            {item.pass_type.name}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '15px',
                                lineHeight: '120%',
                                color: '#646668',
                            }}
                        >
                            {item.end_at}
                            {diffDays > 0 &&
                                ` (еще ${diffDays} ${declension(diffDays, [
                                    'день',
                                    'дня',
                                    'дней',
                                ])})`}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px solid #2597F4',
                            borderRadius: '0.5rem',
                            padding: '0 0.25rem',
                            cursor: 'pointer',
                        }}
                        onClick={() => setExpanded((prev) => !prev)}
                    >
                        <ArrowBackIosIcon
                            sx={{
                                color: '#2597F4',
                                transition: '.2s ease-in-out',
                                rotate: expanded ? '90deg' : '-90deg',
                                marginBottom: expanded ? 'none' : '0.5rem',
                                marginTop: expanded ? '0.5rem' : 'none',
                            }}
                        />
                    </Box>
                </Box>
                <Collapse
                    in={expanded}
                    sx={{
                        padding: '0.5rem 0.5rem 0 0.5rem',
                    }}
                >
                    {(isFetching && <CircleOutlined />) ||
                        (history?.length ? (
                            history?.map((item) => (
                                <ListHistoryItem key={item.id} item={item} />
                            ))
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    color: '#646668',
                                    textAlign: 'center',
                                }}
                            >
                                Ничего не найдено
                            </Typography>
                        ))}
                </Collapse>
            </Box>
            {showModal && (
                <UpdateStatusModal
                    onClose={() => setShowModal(false)}
                    id={item.id}
                />
            )}
        </>
    );
};
