import {
    Box,
    Button,
    Chip,
    List,
    ListItemText,
    Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {TListItemEntity, TListItemHistoryEntity} from 'src/services/lists';
import {Empty} from './components';
import moment from 'moment-timezone';
import {UpdateStatusModal} from '../UpdateStatusModal';

interface IProps {
    readonly item: TListItemEntity;
    readonly history: TListItemHistoryEntity[];
}

export const ViewListItem: React.FC<IProps> = ({item, history}) => {
    const [showModal, setShowModal] = useState(false);

    const titleText = (text: string) => (
        <Typography
            sx={{fontSize: '13px', color: '#646668', lineHeight: '120%'}}
        >
            {text}
        </Typography>
    );

    const infoText = (text: string) => (
        <Typography
            sx={{fontSize: '17px', color: '#000', lineHeight: '120%'}}
        >
            {text}
        </Typography>
    );

    const renderHistoryItem = (historyItem: TListItemHistoryEntity) => {
        return (
            <Box
                key={historyItem.id}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    border: '1px solid #CFE8FC',
                    borderRadius: '1rem',
                    padding: '0.5rem',
                    // minWidth: '448px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '1rem',
                        alignItems: 'center',
                    }}
                >
                    <Chip
                        sx={{
                            border: 'none',
                            height: '28px',
                        }}
                        variant="outlined"
                        label={
                            <Typography
                                sx={{
                                    fontSize: '17px',
                                    lineHeight: '120%',
                                    color: '#000',
                                }}
                            >
                                {historyItem.status}
                            </Typography>
                        }
                    />
                    <Typography
                        sx={{
                            fontSize: '15px',
                            color: '#646668',
                            lineHeight: '120%',
                        }}
                    >
                        {moment(historyItem.created_at).format(
                            'DD.MM.YYYY HH:mm'
                        )}
                    </Typography>
                </Box>
                {historyItem.comment && (
                    <Typography
                        sx={{
                            color: '#000',
                            fontSize: '17px',
                            fontWeight: '500',
                            lineHeight: '120%',
                        }}
                    >
                        {historyItem.comment}
                    </Typography>
                )}
                <Typography
                    sx={{
                        fontSize: '15px',
                        color: '#646668',
                        lineHeight: '120%',
                    }}
                >
                    {historyItem.actor_name}
                </Typography>
            </Box>
        );
    };

    return (
        <>
            <Box sx={{padding: '1rem', bgcolor: 'background.paper'}}>
                <Typography
                    sx={{
                        fontWeight: '500',
                        fontSize: '17px',
                        whiteSpace: 'nowrap',
                        minWidth: '19rem',
                        lineHeight: '120%',
                        color: '#000',
                    }}
                >
                    Информация о списке
                </Typography>
                {item ? (
                    <>
                        <List sx={{width: '100%'}}>
                            <ListItemText
                                primary={titleText('ID')}
                                secondary={infoText(String(item.id))}
                            />
                            <ListItemText
                                primary={titleText('Годен до')}
                                secondary={infoText(item.end_at)}
                            />
                            <ListItemText
                                primary={titleText('Выгрузка')}
                                secondary={infoText('')}
                            />
                            <ListItemText
                                primary={titleText('Владелец')}
                                secondary={infoText(item.partner.full_name)}
                            />
                            <ListItemText
                                primary={titleText('Тип пропуска')}
                                secondary={infoText(item.pass_type.name)}
                            />
                        </List>
                        <Box
                            sx={{
                                paddingBottom: '1rem',
                            }}
                        >
                            {titleText('Действия')}
                            <Button
                                variant="contained"
                                onClick={() => setShowModal(true)}
                                sx={{marginTop: '0.5rem'}}
                            >
                                Изменить статус
                            </Button>
                        </Box>
                        {history?.length ? (
                            <Box>
                                {titleText('Логи')}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.5rem',
                                        paddingTop: '0.5rem',
                                    }}
                                >
                                    {history.map(renderHistoryItem)}
                                </Box>
                            </Box>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <Empty/>
                )}
            </Box>
            {showModal && (
                <UpdateStatusModal
                    onClose={() => setShowModal(false)}
                    id={item.id}
                />
            )}
        </>
    );
};
