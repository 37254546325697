import { useCallback, useEffect, useState } from 'react';
import { ListsService, TListItemEntity } from 'src/services/lists';

export const useLists = () => {
    const [selectedStatus, setSelectedStatus] = useState<number>();

    return {
        selectedStatus,
        setSelectedStatus
    };
};
