import { Box, Button, ButtonGroup, SxProps, Typography } from '@mui/material';
import { TGetZonesEntity, TGetZonesUsersEntity } from 'src/services';
import { ZonesResetModal } from '../../../../common/ZonesResetModal';
import { useState } from 'react';
import { ZonesRemoveModal } from '../../../../common/ZonesRemoveModal';

interface IProps {
    readonly item: TGetZonesEntity;
    readonly userData: TGetZonesUsersEntity;
}

export const ZoneItem: React.FC<IProps> = ({ item, userData }) => {
  const [showResetModal, setShowResetModal] = useState(false)
  const [showRemoveModal, setShowRemoveModal] = useState(false)

    const sx: Record<string, SxProps> = {
        item: {
            borderBottom: '1px solid #CFE8FC',
            padding: '.5rem .75rem',
            display: 'flex',
            gap: '.5rem',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            '&:last-child': {
                borderBottom: 'none !important',
            },
        },
        title: {
            fontSize: '15px',
            fontWeight: 'bold',
        },
        info: {
            fontSize: '12px',
            color: '#8A8C8F',
        },
        buttonsContainer: {
            '&>button': {
                textTransform: 'none',
            },
        },
        button: {
          fontSize: '11px',
          padding: '0 0.5rem'
        }
    };

    return (
        <>
          <Box sx={sx.item}>
              <Box>
                  <Typography sx={sx.title}>{item.combined_name}</Typography>
                  {/* <Typography sx={sx.info}>{item.combined_name}</Typography> */}
              </Box>
              <ButtonGroup orientation="vertical" sx={sx.buttonsContainer}>
                  <Button key="one" size="small" onClick={() => setShowResetModal(true)} sx={sx.button}>
                      Переназначить
                  </Button>
                  <Button key="two" size="small" sx={sx.button} onClick={() => setShowRemoveModal(true)}>
                      Снять с зоны
                  </Button>
              </ButtonGroup>
          </Box>
          {showResetModal && <ZonesResetModal item={userData} onClose={() => setShowResetModal(false)} />}
          {showRemoveModal && <ZonesRemoveModal item={userData} onClose={() => setShowRemoveModal(false)} />}
        </>
    );
};
