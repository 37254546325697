import { Paper } from '@material-ui/core';
import { Box, Button, Collapse, SxProps, Typography } from '@mui/material';
import { TGetZonesUsersEntity } from 'src/services';
import { useZones } from '../../../../hooks/useZones';
import { ZoneItem } from './components/ZoneItem';
import { useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface IProps {
    readonly item: TGetZonesUsersEntity;
}

export const CardItem: React.FC<IProps> = ({ item }) => {
    const { selectedZones, onAssign, isAssignLoading } = useZones();
    const [expanded, setExpanded] = useState(true);
    const userZonesIds = item?.zones?.map((zone) => zone.id) || [];
    const notAssigned = selectedZones?.filter((i) =>
        userZonesIds.includes(i.id)
    )?.length;

    const sx: Record<string, SxProps> = {
        header: {
            color: '#0B7DDA',
            fontWeight: 500,
            padding: '.5rem .75rem 0 .75rem',
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
        },
        button: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '.5rem .75rem 0 .75rem',
        },
        expandIcon: {
            transition: 'all .1s ease-out;',
            transform: `rotate(${expanded ? '90' : '-90'}deg)`,
            cursor: 'pointer',
        },
        collapse: {
            display: 'flex',
            color: '#8A8C8F',
            paddingLeft: '.75rem',
            cursor: item?.zones?.length && 'pointer',
        },
    };

    const handleAssign = () => {
        onAssign({
            user: item,
        });
    };

    return (
        <Paper>
            <Box sx={{ paddingBottom: '.5rem' }}>
                <Typography variant="h6" display="block" sx={sx.header}>
                    {item.name}
                </Typography>
                <Box sx={sx.list}>
                    <Box sx={sx.button}>
                        {!notAssigned && selectedZones?.length ? (
                            <Button
                                variant="contained"
                                sx={{ fontSize: '14px', textTransform: 'none' }}
                                onClick={handleAssign}
                                disabled={isAssignLoading}
                            >
                                Назначить
                            </Button>
                        ) : (
                            <div />
                        )}
                        <Box
                            sx={sx.collapse}
                            onClick={() => {
                                if (item?.zones?.length) {
                                    setExpanded((prev) => !prev);
                                }
                            }}
                        >
                            <Typography variant="body2">
                                {!item?.zones?.length
                                    ? 'Зоны не назначены'
                                    : `Назначенные зоны (${item?.zones?.length})`}
                            </Typography>
                            {item?.zones?.length && (
                                <ChevronRightIcon sx={sx.expandIcon} />
                            )}
                        </Box>
                    </Box>
                    {item?.zones?.length ? (
                        <Collapse in={expanded}>
                            {item.zones.map((zone) => (
                                <ZoneItem
                                    key={`user.${item.id}.zone.${zone.id}`}
                                    item={zone}
                                    userData={item}
                                />
                            ))}
                        </Collapse>
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
        </Paper>
    );
};
