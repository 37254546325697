import { Box, SxProps } from '@mui/material';
import { ZonesProvider } from './hooks/useZones';
import { Employees, Selection, Zones } from './components';


export const ZonesPage: React.FC = () => {
    const sx: Record<string, SxProps> = {
        gridContainer: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
            flexWrap: 'nowrap',
            alignItems: 'stretch',
            height: '100%',
        },
        gridItem: {
            padding: '0 1rem',
            borderRight: '1px solid #E7F3FE',
            '&:last-child': {
                borderRight: 'none !important',
            },
            overflow: 'auto',
            maxHeight: 'calc(100vh - 3rem)',
        },
    };
    return (
        <ZonesProvider>
            <Box sx={sx.gridContainer}>
                <Box sx={sx.gridItem}>
                    <Zones />
                </Box>
                <Box sx={sx.gridItem}>
                    <Employees />
                </Box>
                <Box sx={sx.gridItem}>
                    <Selection />
                </Box>
            </Box>
        </ZonesProvider>
    );
};
