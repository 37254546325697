import { Box } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';

interface IProps {
    readonly status: string;
    readonly onClick?: () => void;
}

export const ItemStatus: React.FC<IProps> = ({ status, onClick }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.25rem',
                border: '1px solid #E5E6E6',
                borderRadius: '0.25rem',
                padding: '0.25rem 0.5rem',
                cursor: onClick ? 'pointer' : 'default',
                fontSize: '15px',
                lineHeight: '120%',
            }}
            onClick={onClick}
        >
            <span>{status}</span>
            {onClick && <EditIcon sx={{ fontSize: '16px' }} />}
        </Box>
    );
};
