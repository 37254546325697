import {FC} from 'react';
import {Route, Routes} from 'react-router-dom';
import {Login} from './login/Login';
import {Appeals} from './appeals/Appeals';
import {Restricted} from './restricted/Restricted';
import {Cards} from './cards/Cards';
import {Sos} from './sos/Sos';
import {Lyon} from './lyon/Lyon';
import {More} from './more/More';
import {Provider} from 'react-redux';
import {store} from '../../store';
import {ListsPage} from './components';

export const MobileApp: FC = () => {
    return (
        <Provider store={store}>
            <Routes>
                <Route path="/" element={<Restricted/>}>
                    <Route path="appeals" element={<Appeals/>}/>
                    <Route path="cards" element={<Cards/>}/>
                    <Route path="sos" element={<Sos/>}/>
                    <Route path="lion" element={<Lyon/>}/>
                    <Route path="lists" element={<ListsPage/>}/>
                    <Route path="more" element={<More/>}/>
                </Route>
                <Route path="login" element={<Login/>}/>
            </Routes>
        </Provider>
    );
};
