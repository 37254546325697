import { Box, Typography } from '@mui/material';
import React from 'react';
import { TListItemHistoryEntity } from 'src/services/lists';
import { ItemStatus } from '../ItemStatus';
import moment from 'moment-timezone';

interface IProps {
    readonly item: TListItemHistoryEntity;
}

export const ListHistoryItem: React.FC<IProps> = ({ item }) => {
    return (
        <Box
            sx={{
                borderTop: '1px solid #CFE8FC',
                padding: '0.5rem 0',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                }}
            >
                <ItemStatus status={item.status} />
                <Typography
                    sx={{
                        color: '#97999B',
                        fontSize: '13px',
                        lineHeight: '120%',
                    }}
                >
                    {moment(item.created_at).format('DD.MM.YYYY')}
                </Typography>
            </Box>
            <Typography
                sx={{
                    fontSize: '15px',
                    lineHeight: '120%',
                    fontWeight: '600',
                }}
            >
                {item.comment}
            </Typography>
            <Typography
                sx={{
                    color: '#97999B',
                    fontSize: '13px',
                    lineHeight: '120%',
                }}
            >
                {item.actor_name}
            </Typography>
        </Box>
    );
};
