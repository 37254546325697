import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ZonesService } from 'src/services';
import { FormAsyncSelect } from '../FormAsyncSelect';
import { TAsyncSelectProps } from '../FormAsyncSelect/FormAsyncSelect.types';

type TProps = Partial<TAsyncSelectProps>;

export const AsyncSelectZonesUsers: React.FC<TProps> = ({ name, ...rest }) => {
    const { control } = useFormContext();
    const { field } = useController({
        name,
        control,
    });

    const fetch = (value: string) =>
        ZonesService.getZonesUsers({
            name: value,
        }).then((res) =>
            res.map((item) => ({
                label: item.name,
                value: item.id,
            }))
        );

    return (
        <FormAsyncSelect
            name={name}
            defaultValue={field?.value?.full}
            fetch={fetch}
            {...rest}
        />
    );
};
