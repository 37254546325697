import { Paper } from '@material-ui/core';
import { Box, SxProps, Typography } from '@mui/material';
import { TGetZonesUsersEntity } from 'src/services';
import { ZoneItem } from './components/ZoneItem';

interface IProps {
    readonly item: TGetZonesUsersEntity;
}

export const CardItem: React.FC<IProps> = ({ item }) => {

    const sx: Record<string, SxProps> = {
        header: {
            color: '#0B7DDA',
            fontWeight: 500,
            padding: '.5rem .75rem 0 .75rem',
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
        },
    };

    return (
        <Paper>
            <Box sx={{ paddingBottom: '.5rem' }}>
                <Typography variant="h6" display="block" sx={sx.header}>
                    {item.name}
                </Typography>
                <Box sx={sx.list}>
                    {item?.zones?.length ? (
                        item.zones.map((zone) => (
                            <ZoneItem key={`selection.${item.id}.zone.${zone.id}`} item={item} zone={zone} />
                        ))
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
        </Paper>
    );
};
