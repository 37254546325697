export const LIST_STATUSES = [
    {
        id: 0,
        name: 'Новый',
    },
    {
        id: 1,
        name: 'Контроль',
    },
    {
        id: 2,
        name: 'Отсутствует',
    },
    {
        id: 3,
        name: 'Обходной',
    },
    {
        id: 4,
        name: 'Удален',
    },
];
