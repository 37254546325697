import { AppBar as RaAppBar, TitlePortal } from 'react-admin';
import MyCustomComponent from './MyCustomComponent'; // Ваш пользовательский компонент

const MyAppBar = (props) => {
    return (
        <RaAppBar {...props}>
            {/* Заголовок приложения */}
            <TitlePortal />

            {/* Дополнительные элементы справа */}
            <div style={{ flexGrow: 1 }} /> {/* Пустой div для выравнивания */}
            <MyCustomComponent />
        </RaAppBar>
    );
};

export default MyAppBar;