import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    SxProps,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    BaseModal,
} from 'src/components/legacy/admin/components/ui';
import { useZones } from 'src/components/legacy/admin/zones/hooks/useZones';
import { TZonesRemoveFormValues } from 'src/components/legacy/admin/zones/types';
import { TGetZonesUsersEntity } from 'src/services';
interface IProps {
    readonly item: TGetZonesUsersEntity;
    readonly onClose: () => void;
}

export const ZonesRemoveModal: React.FC<IProps> = ({ item, onClose }) => {
    const { onRemove } = useZones();
    const [loading, setLoading] = useState(false);

    const methods = useForm<TZonesRemoveFormValues>({
        defaultValues: {
            selectedZones: [],
        },
    });
    const watchSelectedZones = methods.watch('selectedZones');

    const onSubmit = async () => {
        const values = methods.getValues();

        if (!values.selectedZones?.length) {
            return;
        }

        setLoading(true);
        await onRemove({
            values,
            userId: item.id,
            callback: onClose
        });
        setLoading(false);
    };

    const sx: Record<string, SxProps> = {
        title: {
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '25rem',
            overflow: 'auto',
        },
        subTitle: {
            color: '#646668',
            fontSize: '15px',
            textAlign: 'center',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'end',
            paddingTop: '16px',
        },
        divider: {
            margin: '4px 0',
        },
    };

    return (
        <BaseModal
            open
            onClose={onClose}
            sxContent={{
                width: 600,
            }}
        >
            <>
                <Typography sx={sx.title}>{item.name}</Typography>
                <Typography sx={sx.subTitle}>
                    Выделите зоны, с которых сотрудник будет снят
                </Typography>
                <Divider sx={sx.divider} />
                <Box sx={sx.list}>
                    {item?.zones?.map((zone) => {
                        return (
                            <FormControlLabel
                                label={zone.combined_name}
                                control={
                                    <Checkbox
                                        value={zone.id}
                                        onChange={(e, checked) => {
                                            const value = e.target.value;
                                            methods.setValue('selectedZones', [
                                                ...watchSelectedZones?.filter(
                                                    (i) => i !== value
                                                ),
                                                ...(checked ? [value] : []),
                                            ]);
                                        }}
                                    />
                                }
                            />
                        );
                    })}
                </Box>

                <Divider sx={sx.divider} />
                <Box sx={sx.buttons}>
                    <Button onClick={onClose} disabled={loading}>Отмена</Button>
                    <Button onClick={methods.handleSubmit(onSubmit)} disabled={loading}>
                        {loading && <CircularProgress />}Снять с выделенных зон
                    </Button>
                </Box>
            </>
        </BaseModal>
    );
};
