import instance from '../api'
import { TGetZonesRequest, TGetZonesResponse, TGetZonesUsersRequest, TGetZonesUsersResponse, TPostZonesAssignMultipleRequest, TPostZonesAssignRequest, TPostZonesRemoveRequest } from './type'

export const ZonesService = {
  getZones: async ({ parentId }: TGetZonesRequest = {}): Promise<TGetZonesResponse> => {
    const res = await instance.get(`/admin/zones${parentId ? `?parent_id=${parentId}` : ''}`)
    return res.data
  },
  getZonesUsers: async (params: TGetZonesUsersRequest): Promise<TGetZonesUsersResponse> => {
    const res = await instance.get('/admin/zones/users', {
      params
    })
    // ${name ? `?name=${name}` : ''}
    return res.data
  },
  postZonesAssign: async (body: TPostZonesAssignRequest) => {
    const res = await instance.post(`/admin/zones/users/assign`, body)
    return res.data
  },
  postZonesAssignMultiple: async (body: TPostZonesAssignMultipleRequest) => {
    const res = await instance.post(`/admin/zones/users/assign_multiple`, body)
    return res.data
  },
  postZonesRemove: async (body: TPostZonesRemoveRequest) => {
    const res = await instance.post(`/admin/zones/users/remove`, body)
    return res.data
  }
}
