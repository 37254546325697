import { InputAdornment, SxProps, TextField } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';

interface IProps {
    readonly name: string;
}

export const SearchField: React.FC<IProps> = ({ name }) => {
    const { control, register } = useFormContext();
    const {
        fieldState: { error },
    } = useController({ control, name });

    const sx: Record<string, SxProps> = {
        textField: {
            backgroundColor: '#E7F3FE',
            width: '100%',
            marginTop: 0,
            '&.MuiFilledInput-root': {},
            '& input': {
                paddingTop: '.5rem !important',
                color: '#085391 !important',
            },
        },
        icon: {
            marginTop: '0 !important',
            '& svg>path': {
                fill: '#085391',
            },
        },
    };

    return (
        <TextField
            placeholder="Поиск по всем сотрудникам"
            type="search"
            variant="filled"
            {...register(name)}
            error={Boolean(error?.message)}
            helperText={error?.message}
            sx={sx.textField}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" sx={sx.icon}>
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};
