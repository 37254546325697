import {
    Datagrid,
    Identifier,
    List,
    Pagination,
    RowClickFunction,
    TextField,
} from 'react-admin';
import {useLists} from './hooks/useLists';
import {Box} from '@mui/material';
import {ViewListItem} from './components';
import {TListItemEntity} from 'src/services/lists';

export const ListsPage: React.FC = () => {
    const {listItem, setSelectedRowId, listItemHistory, selectedRowId} =
        useLists();
    const filters = [];

    const handleRowClick: RowClickFunction = (id: Identifier) => {
        setSelectedRowId(String(id));
        return '';
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <Box>
                <List
                    filters={filters}
                    perPage={10}
                    pagination={
                        <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]}/>
                    }
                >
                    <Datagrid
                        rowClick={handleRowClick}
                        bulkActionButtons={false}
                        rowStyle={(record: TListItemEntity) => ({
                            backgroundColor:
                                String(record.id) === String(selectedRowId)
                                    ? '#90caf929'
                                    : 'transparent',
                        })}
                    >
                        <TextField source="id" label="ID"/>
                        <TextField source="pass_type.name" label="Тип пропуска"/>
                        <TextField source="end_at" label="Годен до"/>
                        <TextField
                            source="partner.full_name"
                            label="ФИО владельца"
                        />
                        <TextField source="location.name" label="ТТ"/>
                        <TextField source="" label="Ответственный"/>
                        <TextField source="status.name" label="Статус"/>
                        <TextField source="comment" label="Комментарий"/>
                    </Datagrid>
                </List>
            </Box>
            <ViewListItem item={listItem} history={listItemHistory}/>
        </Box>
    );
};
