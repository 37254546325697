import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  ShowButton,
  TextField,
} from 'react-admin';
import React from 'react';

export const JobTitleList = () => (
    <List>
      <Datagrid exporter={false}>
        <TextField source="id" label="ID"/>
        <TextField source="name" label="Название"/>
        <TextField source="sort" label="Сортировка"/>
      </Datagrid>
    </List>
);