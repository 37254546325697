import React, { useState } from 'react';
import { Box, Button, Chip, TextField, Typography } from '@mui/material';
import { LIST_STATUSES } from 'src/utils';
import { BaseModal } from 'src/components/legacy/admin/components/ui';
import { useUpdateListStatusMutation } from 'src/api/lists';

interface IProps {
    readonly onClose: () => void;
    readonly id: number;
}

export const UpdateStatusModal: React.FC<IProps> = ({ onClose, id }) => {
    const [selectedId, setSelectedId] = useState<number>();
    const [comment, setComment] = useState('');
    const [updateStatus, { isLoading }] = useUpdateListStatusMutation();

    const handleSubmit = async () => {
        if (!String(selectedId)) {
            return;
        }

        updateStatus({
            id,
            body: {
                comment,
                status: LIST_STATUSES.find((i) => i.id === selectedId),
            },
        });
    };

    return (
        <BaseModal
            open
            onClose={onClose}
            sxContent={{
                width: '100%',
                minWidth: 'auto',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>
                    Статус
                </Typography>
                <Typography sx={{ fontSize: '15px', color: '#7D8083' }}>
                    Выберите статус и/или напишите комментарий
                </Typography>
                <Box sx={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap' }}>
                    {LIST_STATUSES.map((i) => (
                        <Chip
                            label={i.name}
                            variant={
                                i.id === selectedId ? undefined : 'outlined'
                            }
                            onClick={() => setSelectedId(i.id)}
                        />
                    ))}
                </Box>
                <TextField
                    placeholder="Текст комментария"
                    multiline
                    rows={6}
                    variant="outlined"
                    onChange={(e) => {
                        setComment(e.target.value);
                    }}
                />
                <Box>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        Сохранить
                    </Button>
                    <Button onClick={onClose} disabled={isLoading}>
                        Отменить
                    </Button>
                </Box>
            </Box>
        </BaseModal>
    );
};
