import { Box, Modal, ModalProps, SxProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type TProps = ModalProps & {
    sxContent?: SxProps;
};

export const BaseModal: React.FC<PropsWithChildren<TProps>> = ({
    children,
    sxContent,
    ...rest
}) => {
    const sx: Record<string, SxProps> = {
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        content: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 400,
            bgcolor: 'background.paper',
            borderRadius: '4px',
            boxShadow: 24,
            p: 2,
            ...sxContent,
        },
        wrapper: {},
    };

    return (
        <Modal sx={sx.modal} {...rest}>
            <Box sx={sx.content}>{children}</Box>
        </Modal>
    );
};
