import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useNotify } from 'react-admin';
import {
    TGetZonesEntity,
    TGetZonesUsersEntity,
    ZonesService,
} from 'src/services';
import {
    TZoneAssign,
    TZonesRemoveFormValues,
    TZonesResetFormValues,
} from '../types';

type TReturnValues = {
    readonly selectedZones: TGetZonesEntity[];
    readonly setSelectedZones: (value: TGetZonesEntity[]) => void;
    readonly zones: TGetZonesEntity[];
    readonly users: TGetZonesUsersEntity[];
    readonly assignedUsers: TGetZonesUsersEntity[];
    readonly getZones: (value?: number) => void;
    readonly getZonesUsers: (value?: string) => void;
    readonly isUsersLoading: boolean;
    readonly isAssignLoading: boolean;
    readonly onAssign: (value: TZoneAssign) => Promise<void>;
    readonly onReassign: (value: {
        values: TZonesResetFormValues;
        userId: number;
        callback: () => void;
    }) => Promise<void>;
    readonly onRemove: (value: {
        values: TZonesRemoveFormValues;
        userId: number;
        callback: () => void;
    }) => Promise<void>;
};

const ZonesContext = createContext<Partial<TReturnValues>>({});

export const ZonesProvider = ({
    children,
}: {
    children: JSX.Element | JSX.Element[];
}) => {
    const [selectedZones, setSelectedZones] = useState<TGetZonesEntity[]>([]);
    const [zones, setZones] = useState<TGetZonesEntity[]>([]);
    const [users, setUsers] = useState<TGetZonesUsersEntity[]>([]);
    const [assignedUsers, setAssignedUsers] = useState<TGetZonesUsersEntity[]>(
        []
    );
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [isAssignLoading, setIsAssignLoading] = useState(false);
    const [search, setSearch] = useState<string>();
    const notify = useNotify();

    const getZones = useCallback(async () => {
        const response = await ZonesService.getZones();
        setZones(response);
    }, []);

    const getZonesUsers = useCallback(async (name?: string) => {
        setIsUsersLoading(true);
        const response = await ZonesService.getZonesUsers({ name });
        setUsers(response);
        setIsUsersLoading(false);
        setSearch(name);
    }, []);

    const getAssignedUsers = useCallback(async () => {
        if (!selectedZones?.length) {
            return;
        }

        const response = await ZonesService.getZonesUsers({
            zone_ids: selectedZones?.map((item) => item.id).join(',') || '',
        });
        setAssignedUsers(response);
    }, [selectedZones]);

    const refetchAll = useCallback(async () => {
        setZones([]);
        setSelectedZones([])
        getZones();
        getZonesUsers(search);
        getAssignedUsers();
    }, [getZones, getZonesUsers, getAssignedUsers, search]);

    const onReassign = useCallback(
        async ({ values, userId, callback }) => {
            try {
                await ZonesService.postZonesAssignMultiple({
                    user_id: values.user,
                    zone_ids: values.selectedZones,
                });

                await ZonesService.postZonesRemove({
                    user_id: userId,
                    zone_ids: values.selectedZones,
                });
                refetchAll();
                callback();
            } catch (error: any) {
                notify(error.message, { type: 'error' });
            }
        },
        [notify, refetchAll]
    );

    const onRemove = useCallback(
        async ({ values, userId, callback }) => {
            try {
                await ZonesService.postZonesRemove({
                    user_id: userId,
                    zone_ids: values.selectedZones,
                });
                refetchAll();
                callback();
            } catch (error: any) {
                notify(error.message, { type: 'error' });
            }
        },
        [notify, refetchAll]
    );

    const onAssign = useCallback(
        async ({ user }: TZoneAssign): Promise<any> => {
            if (!selectedZones?.length) {
                notify('Зона не выбрана', { type: 'error' });
                return;
            }
            try {
                setIsAssignLoading(true);

                const zoneIds = selectedZones.map((i) => String(i.id));
                await ZonesService.postZonesAssignMultiple({
                    user_id: user.id,
                    zone_ids: zoneIds,
                });

                setZones([]);
                await getZones();
                await getZonesUsers(search);

                setSelectedZones([]);
                setAssignedUsers([]);
            } catch (error) {
                notify(error.message, { type: 'error' });
            } finally {
                setIsAssignLoading(false);
            }
        },
        [getZones, getZonesUsers, notify, search, selectedZones]
    );

    useEffect(() => {
        if (selectedZones?.length) {
            getAssignedUsers();
        } else {
            setAssignedUsers([]);
        }
    }, [selectedZones, getAssignedUsers]);

    const values = useMemo(
        () => ({
            selectedZones,
            setSelectedZones,
            zones,
            getZones,
            users,
            getZonesUsers,
            isUsersLoading,
            isAssignLoading,
            onAssign,
            assignedUsers,
            onReassign,
            onRemove,
        }),
        [
            selectedZones,
            zones,
            users,
            isUsersLoading,
            assignedUsers,
            isAssignLoading,
            getZonesUsers,
            getZones,
            setSelectedZones,
            onAssign,
            onReassign,
            onRemove,
        ]
    );

    return (
        <ZonesContext.Provider value={values}>{children}</ZonesContext.Provider>
    );
};

export const useZones = (): Partial<TReturnValues> => {
    return {
        ...useContext(ZonesContext),
    };
};
