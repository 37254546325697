import { Box, Skeleton, SxProps } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SearchField } from '../../../components/ui';
import { useZones } from '../../hooks/useZones';
import { TFormValues } from './type';
import { useDebounce } from '../../../../../../hooks/useDebounce';
import { Empty } from './components/Empty';
import { CardItem } from './components/CardItem';

export const Employees: React.FC = () => {
    const { getZonesUsers, users, isUsersLoading } = useZones();
    const methods = useForm<TFormValues>();
    const { watch } = methods;
    const watchFields = watch();
    const debouncedSearchValue = useDebounce<string>(watchFields.search);

    const renderItems = () => {
        return users?.length ? (
            users?.map((item) => (
                <CardItem key={`emp-${item.id}`} item={item} />
            ))
        ) : (
            <Empty />
        );
    };

    const sx: Record<string, SxProps> = {
        container: {
            paddingTop: '1rem',
            display: 'flex',
            gap: '.5rem',
            flexDirection: 'column',
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            gap: '.75rem',
        },
        skeleton: {
            width: '100%',
        },
    };

    useEffect(() => {
        getZonesUsers(debouncedSearchValue);
    }, [debouncedSearchValue, getZonesUsers]);

    return (
        <Box sx={sx.container}>
            <FormProvider {...methods}>
                <SearchField name="search" />
            </FormProvider>
            <Box sx={sx.list}>
                {isUsersLoading ? (
                    <Box>
                        {Array(5)
                            .fill('')
                            .map((_, index) => (
                                <Skeleton key={`skel-${index}`} />
                            ))}
                    </Box>
                ) : (
                    renderItems()
                )}
            </Box>
        </Box>
    );
};
