import { Box, Stack } from '@mui/material';
import { Card } from './Card';
import { useGetPassesQuery } from '../../../api/passes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PassStatusFilterState } from '../../../store/passes/passesStatusFilter';
import { useState } from 'react';
import { Pass } from 'src/models/Passes';
import { QRCodeSVG } from 'qrcode.react';

export const CardList = () => {
    const [selectedItem, setSelectedItem] = useState<Pass>();

    const userId = useSelector<RootState, number>(
        (state) => state.auth.userId!
    );
    const statusFilters = useSelector<RootState, PassStatusFilterState>(
        (state) => state.passesStatusFilterSlice
    );
    const { data: passes, isFetching } = useGetPassesQuery(
        { userId, status: statusFilters },
        {
            refetchOnMountOrArgChange: true,
        }
    );
    const emptyPasses: Pass[] = new Array(3).fill(0);

    const qrString = () => {
        const {
            id,
            partner: { full_name: name },
            location: { name: location },
            valid_until_text: date,
        } = selectedItem;

        return `#${id}, ${name}, ${location}, ${date}`;
    };

    return (
        <Stack spacing={2} p={1} pt={0}>
            {(passes ?? emptyPasses)
                .filter((i) => !selectedItem || i.id === selectedItem.id)
                .map((pass, i) => (
                    <Box
                        sx={{ cursor: 'pointer' }}
                        key={typeof pass === 'number' ? i : pass.id}
                        onClick={() =>
                            setSelectedItem(!selectedItem ? pass : undefined)
                        }
                    >
                        <Card
                            key={typeof pass === 'number' ? i : pass.id}
                            pass={typeof pass === 'number' ? undefined : pass}
                            isLoading={isFetching}
                        />
                    </Box>
                ))}
            {selectedItem && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <QRCodeSVG value={qrString()} size={128} level="H" />
                </Box>
            )}
        </Stack>
    );
};
