import {fetchUtils, Admin, Resource, defaultLightTheme} from 'react-admin';
import React from 'react';
import jsonServerProvider from 'ra-data-json-server';
import {PassList} from './pass/list';
import * as config from '../../../config';
import authProvider from './auth';
import ru from 'ra-language-russian';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {LocationList} from './locations/list';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import {LocationShow} from './locations/show';
import {JobTitleList} from './job_titles/list';
import {UserList} from './users/list';
import CustomNotification from './components/custom_notification';
import {PassShow} from './pass/show';
import {RootRedirect} from 'src/components/util/rootRedirect';
import {LOCAL_STORAGE_KEY} from 'src/config';
import {ZonesPage} from './zones/index.tsx';
import MapIcon from '@mui/icons-material/Map';
import {ListsPage} from './lists/index.tsx';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CustomLayout from './components/CustomHeader/CustomLayout.tsx';
import {DataProvider} from 'react-admin';
import {createTheme} from '@mui/material';

const httpClient = (url, options = {}) => {
  const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  options.user = {
    authenticated: true,
    token: 'Bearer ' + token.access_token,
  };
  return fetchUtils.fetchJson(url, options);
};
const baseDataProvider = jsonServerProvider(config.ADMIN_HOST, httpClient);

const dataProvider: DataProvider = {
  ...baseDataProvider, // Копируем стандартные методы

  // Кастомный метод для экспорта
  export: async (resource, params) => {
    const {filter} = params;

    // URL для экспорта
    const url = `${config.ADMIN_HOST}/passes/export`;
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    const options = {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + token.access_token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({filters: filter}),
    };

    // Вызов серверной ручки с фильтрами
    const response = await fetch(url, options);

    return {data: await response.blob()};
  },
};

const translations = {ru};
export const i18nProvider = polyglotI18nProvider(locale => translations[locale],
    'ru', {allowMissing: true});

const theme = createTheme({
  ...defaultLightTheme,
  transitions: {
    // Отключаем все анимации
    create: () => 'none',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'none', // Отключаем анимации для кнопок
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          transition: 'none', // Отключаем анимации для Paper
        },
      },
    },
    // Добавьте другие компоненты, если нужно
  },
});

export function AdminRoot() {
  return (
      <Admin
          notification={CustomNotification}
          basename="/"
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          locale="ru"
          loginPage={<RootRedirect/>}
          layout={CustomLayout}
          darkTheme={null}
          theme={theme}
      >
        <Resource
            name="passes"
            list={PassList}
            show={PassShow}
            options={{label: 'Визитки'}}
            icon={BadgeIcon}
        />
        <Resource
            name="locations"
            list={LocationList}
            show={LocationShow}
            options={{label: 'Торговые точки'}}
            icon={LocationOnIcon}
            recordRepresentation="name"
        />
        <Resource
            list={JobTitleList}
            options={{label: 'Должности'}}
            name="job_titles"
            recordRepresentation="name"
        />
        <Resource
            name="pass_types"
            recordRepresentation="name"
        />
        <Resource
            name="partners"
            recordRepresentation="name"
        />
        <Resource
            name="partner_types"
            recordRepresentation="name"
        />
        <Resource
            list={UserList}
            options={{label: 'Пользователи'}}
            icon={PersonIcon}
            name="users"
            recordRepresentation="name"
        />
        <Resource
            list={ListsPage}
            options={{label: 'Списки'}}
            icon={ListAltIcon}
            name="list_items"
            recordRepresentation="name"
        />
        <Resource
            list={ZonesPage}
            options={{label: 'Зоны'}}
            icon={MapIcon}
            name="zones"
            recordRepresentation="name"
        />
      </Admin>
  );
}
