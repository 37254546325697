import {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {LoadingButton, ToggleButton, ToggleButtonGroup} from '@mui/lab'
import {Stack, TextField, Typography, Box, styled} from '@mui/material';
import {useForm, Controller, RegisterOptions} from 'react-hook-form';
import {useAuthMutation} from '../../../api/auth';
import {getApiErrorMessage} from '../../../models/ApiError';
import {useAuth} from '../../../hooks/useAuth';
import {TTokenType} from '../../../models/Auth';
import AuthService from 'src/services/auth_service';
import {FormatError} from 'src/util';

import logo from 'src/images/logo.png';

interface AuthFormData {
    login: string;
    password: string;
}

const requiredRule: RegisterOptions = {
    required: 'Это поле обязательно',
};
const idRules: RegisterOptions = {
    ...requiredRule,
};
const passwordRules: RegisterOptions = {
    ...requiredRule,
};

const styles = {
    container: {
        flex: '0 0 330px'
    }
}

const LoginBox = styled('div')(({theme}) => ({
    maxWidth: '330px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    padding: '20px',
    marginTop: '30px',
    '& .login__input': {
        marginBottom: theme.spacing(2),
    },
}))

export const Login: FC = () => {
    const isAuth = useAuth();
    const [tokenType, setTokenType] = useState<TTokenType>('mobile');
    const [errorText, setErrorText] = useState<string>('');
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: {isValid},
    } = useForm<AuthFormData>({
        mode: 'onTouched',
        defaultValues: {
            login: '',
            password: '',
        },
    });

    const [auth, {error, isLoading}] = useAuthMutation();
    const errorMessage = getApiErrorMessage(error);

    const onSubmit = async (data: AuthFormData) => {

        switch (tokenType) {
            case 'mobile':
                auth({...data, token_type: tokenType});
                break;
            case 'admin':
                try {
                    await AuthService.login(data.login, data.password, tokenType)
                    setErrorText('')
                    navigate('/ui/admin', {replace: true})
                } catch (e) {
                    setErrorText(FormatError(e))
                }
                break;
        }
    }

    if (isAuth) {
        navigate('/ui/mobile_app', {replace: true})
    }
    
    return (
        <div>
            <LoginBox>
                <Stack direction="column" alignItems="center">
                    <Box component="img" src={logo} sx={{width: '150px'}}/>
                </Stack>
                {(error || errorText)
                    && <Typography
                        textAlign="center"
                        variant="body2"
                        sx={{mb: 2, color: (theme) => theme.palette.error.main}}
                        gutterBottom
                    >
                        {errorText || errorMessage}
                    </Typography>
                }
                <ToggleButtonGroup
                    color="primary"
                    value={tokenType}
                    onChange={(e, newValue) => {
                        if (newValue !== null) {
                            setTokenType(newValue)
                        }
                    }}
                    size="small"
                    sx={{mb: 2}}
                    fullWidth
                    exclusive
                >
                    <ToggleButton value="mobile" color="error">Моб. версия</ToggleButton>
                    <ToggleButton value="admin" color="primary">Админка</ToggleButton>
                </ToggleButtonGroup>
                <form
                    onSubmit={handleSubmit(data => onSubmit(data))}
                >
                    <Stack spacing={2} width={'100%'}>
                        <Controller
                            rules={idRules}
                            name="login"
                            control={control}
                            defaultValue=""
                            render={({field, formState: {errors}}) => (
                                <TextField
                                    {...field}
                                    label="Логин"
                                    variant="outlined"
                                    size="small"
                                    error={!!errors.login}
                                    helperText={errors.login?.message ?? ''}
                                    required
                                />
                            )}
                        />
                        <Controller
                            rules={passwordRules}
                            name="password"
                            control={control}
                            render={({field, formState: {errors}}) => (
                                <TextField
                                    {...field}
                                    label="Пароль"
                                    variant="outlined"
                                    type="password"
                                    size="small"
                                    error={!!errors.password}
                                    helperText={errors.password?.message ?? ''}
                                    required
                                />
                            )}
                        />
                        <LoadingButton
                            loading={isLoading}
                            variant="contained"
                            fullWidth
                            type="submit"
                        >
                            Войти
                        </LoadingButton>
                    </Stack>
                </form>
            </LoginBox>
        </div>
    );
};
