import instance from './api';

class PassService {
  async createPass(
      {
        passTypeID,
        lastName,
        firstName,
        secondName,
        locationID,
        jobTitleID,
        comment,
        halfZone,
        magnetCard,
        openClose,
      }) {

    const params = {
      'pass_type_id': passTypeID,
      'last_name': lastName,
      'first_name': firstName,
      'second_name': secondName,
      'location_id': locationID,
      'job_title_id': jobTitleID,
      'comment': comment,
      'half_zone': halfZone,
      'magnet_card': magnetCard,
      'open_close': openClose,
    };

    const res = await instance.post('/passes', params);
    return res.data;
  }

  async getPassesByUser(userID) {
    const params = {};
    if (userID) {
      params['creator_user_id'] = userID;
    }

    const res = await instance.get(`/passes`, {params: params});
    return res.data;
  }

  async getPassTypes() {
    const res = await instance.get(`/pass_types`);
    return res.data;
  }

  async passConfirm({id, params}) {
    const res = await instance.post(`/passes/${id}/confirm`, params);
    return res.data;
  }

  async passRefuse({id, params}) {
    const res = await instance.post(`/passes/${id}/refuse`, params);
    return res.data;
  }

  async passCancel({id, params}) {
    const res = await instance.post(`/passes/${id}/cancel`, params);
    return res.data;
  }

  async passExtend({id, params}) {
    const res = await instance.post(`/passes/${id}/extend`, params);
    return res.data;
  }
}

export default new PassService();