import {
    Datagrid,
    DateField,
    FunctionField,
    List,
    Pagination,
    ReferenceField,
    SelectField,
    SelectInput,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    TextField,
    useRecordContext,
    WrapperField,
    TopToolbar,
    ExportButton, useListContext, useDataProvider
} from 'react-admin';
import {saveAs} from 'file-saver';
import React from 'react';
import {PassBgColor, PassStatuses, InternalPassTypeName, PassTypeName} from 'src/util';
import {Typography} from '@material-ui/core';
import {Box, Stack} from '@mui/material';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import {PartnerName} from 'src/components/legacy/admin/components/partner_name';
import {format} from "date-fns";

const filters = [
    <SelectInput
        label="По статусу"
        source="status"
        size="small"
        alwaysOn
        emptyText="Все"
        choices={PassStatuses}
        variant="outlined"
    />,
    <SelectInput
        label="По флагу"
        source="to_be_agreed"
        size="small"
        alwaysOn
        choices={[{id: 'true', name: 'На согласовании'}]}
        variant="outlined"
    />,
    <TextInput
        label="Торговая точка"
        source="location_name"
        size="small"
        alwaysOn
        variant="outlined"
        sx={{marginBottom: "4px"}}
    />,
    <ReferenceInput
        label="Автор"
        source="creator_user_id"
        reference="users"
        filterToQuery={(searchText) => ({q: searchText})}
        alwaysOn
    >
        <AutocompleteInput
            label="Автор"
            createLabel="Автор"
            optionText={(e) => e.name + " (" + e.login + ")"}
            size={"small"}
            sx={{marginBottom: "4px", width: 300}}
        />
    </ReferenceInput>,
];

const StatusField: React.FC<{
    source: string;
    label: string;
}> = (props) => {
    const record = useRecordContext();

    //   {
    //   '& .data-grid_regime-violation': {
    //     bgcolor: "#F0E68C"
    //   },
    //   '& .data-grid_status-collection': {
    //     bgcolor: "#00BFFF" синий
    //   },
    //   '& .data-grid_status-repaid': {
    //     bgcolor: "#98FB98" зеленый
    //   },
    //   '& .data-grid_status-cancelled': {
    //     bgcolor: "#FA8072" красный
    //   }
    // }

    return (
        <Box
            sx={{
                bgcolor: PassBgColor(record),
                padding: '0.25rem 0.5rem',
                borderRadius: '0.25rem',
                border: '1px solid',
                textAlign: 'center',
            }}
        >
            <SelectField
                {...props}
                choices={PassStatuses}
                sx={{whiteSpace: 'nowrap'}}
            />
        </Box>
    );
};

const ListFlagsField = () => {
    const record = useRecordContext();

    return (
        <Stack direction="row" alignItems="center" sx={{width: '100%'}}>
            {record.in_absentia && <MarkAsUnreadIcon titleAccess="Заочно"/>}
            {record.online_store && (
                <LocalGroceryStoreIcon titleAccess="Интернет-магазин"/>
            )}
            {record.half_zone && <StrikethroughSIcon titleAccess="Зона 1/2"/>}
            {record.magnet_card && (
                <CreditScoreIcon titleAccess="Магнитная карта"/>
            )}
        </Stack>
    );
};

const DateUntilField: React.FC = () => {
    const record = useRecordContext();
    const validUntil = new Date(record.valid_until);

    const color =
        validUntil < new Date() ? 'tomato' : record.extended ? 'skyblue' : '';

    return (
        <Box color={color}>
            <Typography>
                <DateField source="valid_until"></DateField>
            </Typography>
        </Box>
    );
};

const ListActions = () => {
    const dataProvider = useDataProvider();
    const {filterValues} = useListContext();

    const handleExport = async () => {
        try {
            // Вызов серверной ручки с фильтрами
            const {data} = await dataProvider.export('posts', {
                filter: filterValues, // Передаем фильтры
            });

            const currentDate = format(new Date(), 'yyyy.MM.dd-HH:mm'); // date-fns
            const fileName = `визитки-${currentDate}.xlsx`;

            // Скачиваем файл с помощью file-saver
            saveAs(data, fileName);
        } catch (error) {
            console.error('Export error:', error);
        }
    };

    return (
        <TopToolbar>
            <ExportButton onClick={handleExport}/>
        </TopToolbar>
    );
};

export const PassList: React.FC = () => {
    const postRowStyle = (record: any, index: number) => ({
        backgroundColor: PassBgColor(record),
    });

    return (
        <List
            actions={<ListActions/>}
            filters={filters}
            perPage={200}
            pagination={
                <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]}/>
            }
            exporter={false}
        >
            <Datagrid rowClick="show" rowStyle={postRowStyle}>
                <TextField source="id" label="ID"/>
                <DateField source="created_at" label="Создан"/>
                <WrapperField label="До">
                    <DateUntilField/>
                </WrapperField>
                <ReferenceField
                    label="Автор"
                    reference="users"
                    source="creator_user_id"
                    link={false}
                >
                    <FunctionField
                        render={(record: any) =>
                            `${record.name} (${record.login})`
                        }
                    />
                </ReferenceField>
                <FunctionField label="Тип" render={(record) => PassTypeName(record.pass_type_id)}/>
                <ReferenceField
                    label="Торговая точка"
                    reference="locations"
                    source="location_id"
                    link={false}
                />
                <WrapperField label="На кого">
                    <PartnerName/>
                </WrapperField>
                <ReferenceField
                    label="Должность"
                    reference="partner_types"
                    source="partner_type_id"
                    link={false}
                >
                    <FunctionField render={(record: any) => `${record.name}`}/>
                </ReferenceField>
                <ReferenceField
                    label="&nbsp;"
                    reference="job_titles"
                    source="jot_title_id"
                    link={false}
                >
                    <FunctionField render={(record: any) => `${record.name}`}/>
                </ReferenceField>
                <ListFlagsField/>
                <StatusField source="status" label="Статус"/>
            </Datagrid>
        </List>
    );
};
